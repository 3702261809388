<template>
  <div>
    <b-overlay :show="false" rounded="sm">
      <b-card>
        <b-form class="mt-2" ref="formTask">
          <input type="hidden" name="task_id" v-model="task_id">
          <!-- form -->
          <b-row>
            <b-col sm="2">
              <b-form-group label="Fecha inicio" label-for="date_start">
                <b-form-input type="datetime-local" v-model="start_date" name="date_start"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Fecha Fin. estimada" label-for="date_end">
                <b-form-input type="datetime-local" v-model="end_date" name="date_end"></b-form-input>
              </b-form-group>
            </b-col>
            
            <b-col sm="4">
              <b-form-group label="Título" label-for="description">
                <b-form-input type="text" v-model="description" name="description"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group label="Referencia servicio" label-for="service_referente">
                <b-form-input type="text" v-model="service_referente" name="service_referente"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group label="Tipo" label-for="task_category_id">
                <v-select :options="types" placeholder="Seleccione tipo" label="title" :getOptionKey="getOptionKey"
                  v-model="task_category_id" name="task_category_id" />
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group label="Cliente" label-for="client_id">
                <v-select :options="clients" placeholder="Seleccione cliente" label="title" :getOptionKey="getOptionKey"
                  v-model="client_id" name="client_id" @input="handleInputChange()" />
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group label="Presupuesto" label-for="budget_id">
                <v-select :options="budgets" placeholder="Seleccione presupuesto" label="title"
                  :getOptionKey="getOptionKey" name="budget_id" v-model="budget_id" />
              </b-form-group>
            </b-col>
            <b-col sm="2">
              <b-form-group label="Factura" label-for="invoice_id">
                <v-select :options="invoices" placeholder="Seleccione factura" label="title"
                  :getOptionKey="getOptionKey" name="invoice_id" v-model="invoice_id" />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Notas técnico" label-for="notes">
                <b-form-textarea v-model="notes" name="notes"></b-form-textarea>
              </b-form-group>
            </b-col>

            <b-col sm="4">
              <b-form-group label="Urgente" label-for="urgency">
                <b-form-checkbox v-model="isActive" name="urgency" switch></b-form-checkbox>
              </b-form-group>
            </b-col>
           
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button variant="primary" class="mt-2 mr-1" type="button" @click="saveForm">
                Crear tarea
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { BFormTextarea, BButton, BForm, BOverlay, BCardHeader, BCardBody, BFormGroup, BFormInput, BFormCheckbox, BRow, BCol, BCard, BCardText } from 'bootstrap-vue'
import vSelect from 'vue-select';
export default {
  components: { BFormTextarea, vSelect, BButton, BForm, BOverlay, BCardHeader, BCardBody, BFormGroup, BFormInput, BFormCheckbox, BRow, BCol, BCard, BCardText, },
  data() {
    return {
      task_id: this.$route.params.task,
      start_date: new Date().toISOString().slice(0, 10),
      end_date: new Date().toISOString().slice(0, 10),
      task_category_id: null,
      client_id: null,
      budget_id: null,
      invoice_id: null,
      service_referente: null,
      description: null,
      notes: null,
      isActive: false,
      types: [],
      clients: [],
      budgets: [],
      invoices: [],
    }
  },
  created() {
    this.$http.get("/api/getTypesTask").then((response) => {
      this.types = response.data;
    });
    this.$http.get("/api/getClients").then((response) => {
      this.clients = response.data;
    });
  },
  methods: {

    getOptionKey(option) {
      return option ? option.id : null;
    },
    handleInputChange() {
      this.getBudgetsClient();
      this.getInvoicesClient();    
    },
    getBudgetsClient() {
      this.$http.get("/api/getBudgetsClient?id="+this.client_id.id).then((response) => {
            this.budgets = response.data;       
        })
    },
    getInvoicesClient() {
      this.$http.get("/api/getInvoicesClient?id="+this.client_id.id).then((response) => {
            this.invoices = response.data;       
        })
    },
    saveForm: async function (e) {
      this.show = true;

      if (!this.description) {
        this.$bvToast.toast('ERROR : El título es obligatorio', { variant: 'danger', noCloseButton: true , autoHideDelay: 10000});
        return
      } 
      if (!this.task_category_id) {
        this.$bvToast.toast('ERROR : El tipo de tarea es obligatorio', { variant: 'danger', noCloseButton: true, autoHideDelay: 10000 });
        return
      } 
      if (!this.client_id) {
        this.$bvToast.toast('ERROR : Cliente es obligatorio', { variant: 'danger', noCloseButton: true, autoHideDelay: 10000 });
        return
      } 
      

      var formData = new FormData(this.$refs.formTask);
      formData.set('client_id', this.client_id ? this.client_id.id : null);
      formData.set('task_category_id', this.task_category_id ? this.task_category_id.id : null);
      formData.set('budget_id', this.budget_id ? this.budget_id.id : null);
      formData.set('invoice_id', this.invoice_id ? this.invoice_id.id : null);
      await this.$http({
        method: "post",
        url: "/api/insertTask",
        data: formData,
      }).then(response => {
        let id = response.data;
        this.show = false;
        this.$router.push("/edit-task/" + id);
        this.$bvToast.toast('Tarea creada correctamente. Ahora la puedes editar', { variant: 'success', noCloseButton: true });
      }).catch(response => { window.console.log(response) });

    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>